
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h5 class="mb-3">Create Medicine Request</h5>
        <div class="card loading-viewport">
          <div class="card-body">
            <drug-request-form />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DrugRequestForm from '@/components/forms/DrugRequestForm.vue'

export default {
  components:{
    DrugRequestForm
  },
}
</script>
